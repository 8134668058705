// UserDataContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import useUser from "hooks/useGetUser";

const UserDataContext = createContext();

export const UserDataProvider = ({ children }) => {
  // console.log("inside UserDataContext");
  const [userData, setUserData] = useState(null);

  // console.log("calling useUser from UserDataContext");
  const userId = JSON.parse(localStorage.getItem("userId"));
  const user = useUser(userId);
  // console.log("inside UserDataProvider, user is: ", user);

  //   const { user, loading } = useUser(); // Fetch user data

  useEffect(() => {
    // console.log("should I set userData inside UserDataProvider?");
    if (user) {
      // console.log("setting userData inside UserDataProvider");
      setUserData(user);
    }
  }, [user]);

  return (
    <UserDataContext.Provider value={{ userData }}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => useContext(UserDataContext);
