import React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { checkSession } from "../../network/ApiAxios";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // console.log("inside PrivateRoute");
  const history = useHistory();

  const check = async () => {
    const token = localStorage.getItem("token");
    // console.log("inside PrivateRoute, checking token validity: " + token);
    if (!token) {
      return false;
    }

    try {
      const response = await checkSession(token);
      // console.log("response: " + JSON.stringify(response))
      const { data } = response;
      if (!data.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("username");
        history.push("/auth/login");
      }
      return data.success;
    } catch (err) {
      // console.log("Error while checking token in PrivateRoute: " + err);
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("username");
      history.push("/auth/login");
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") &&
        localStorage.getItem("userId") &&
        check() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
