import { useEffect, useState } from "react";
import { getOneUser } from "network/ApiAxios";

function useUser(userId, api_key) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchUser = async () => {
      if (userId) {
        try {
          const response = await getOneUser(userId);
          const { success, user } = response.data;
          if (success) {
            setUser(user);
          } else {
            localStorage.removeItem("username");
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
          }
        } catch (error) {
          // if user is not logged in, then ask the user to log in again.
          localStorage.removeItem("username");
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          console.log(api_key);
          if (!api_key) {
            window.location.href = "/auth/login";
          }
        }
      } else {
        console.log("No user id provided");
      }
      setLoading(false);
    };

    fetchUser();
  }, [userId]);
  return loading ? null : user;
}

export default useUser;
